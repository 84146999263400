import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

// Modal Video
import ModalVideo from 'react-modal-video';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';

//Import Icons
import FeatherIcon from 'feather-icons-react';

//Import Images
import book from '../../assets/images/book/book.png';
import EbookReader from './EbookReader';

class Section extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isOpenEbookReder: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  bacaEbook() {
    this.setState({ isOpenEbookReder: true });
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  book =
    'http://api-ebook.mediakolaborasi.com/ebooks/ebook_65a4446a6c45a_1.jpg';
  render() {
    return (
      <React.Fragment>
        <section className="bg-half-170 d-table w-100 bg-light">
          <Container>
            <Row className="mt-5 mt-sm-0 align-items-center">
              <Col lg={6} md={6}>
                <div className="title-heading me-lg-4">
                  <h4 className="display-4 fw-bold mb-3">
                    {' '}
                    The Most Comprehensive Book!{' '}
                  </h4>
                  <p className="text-muted para-desc mb-0">
                    Start working with Landrick that can provide everything you
                    need to generate awareness, drive traffic, connect.
                  </p>
                  <div className="mt-4 pt-2">
                    <Link
                      onClick={this.bacaEbook.bind(this)}
                      to="#"
                      className="btn btn-soft-primary m-1"
                    >
                      Baca{' '}
                    </Link>{' '}
                  </div>
                </div>
              </Col>

              <Col lg={6} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div className="bg-white p-5 rounded-md">
                  <img
                    src={this.book}
                    className="img-fluid mx-auto d-block"
                    alt="Landrick"
                  />
                </div>
              </Col>
            </Row>
            <ModalVideo
              channel="vimeo"
              isOpen={this.state.isOpen}
              videoId="99025203"
              onClose={() => this.setState({ isOpen: false })}
            />
          </Container>
        </section>
        <EbookReader isOpen={this.state.isOpenEbookReder}></EbookReader>
      </React.Fragment>
    );
  }
}

export default Section;
