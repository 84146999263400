import React, { Component, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Form, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader';
//Import Images
import bgNature from '../../assets/images/biodiversitas/nature.jpg';
import DisplayResearchers from './DisplayResearchers';

const Homepage = () => {
  const [displayCategory, setDisplayCategory] = useState('All');
  const [filterName, setFilterName] = useState('');
  const [callSearch, setCallSearch] = useState(false);
  const onChangeFilterName = (e) => {
    setFilterName(e.target.value);
  };
  const scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.querySelector('.shoppingbtn').classList.remove('btn-light');
      document.querySelector('.settingbtn').classList.remove('btn-light');
      document.querySelector('.shoppingbtn').classList.add('btn-primary');
      document.querySelector('.settingbtn').classList.add('btn-soft-primary');
      document.getElementById('topnav').classList.add('nav-sticky');
    } else {
      document.querySelector('.shoppingbtn').classList.remove('btn-primary');
      document
        .querySelector('.settingbtn')
        .classList.remove('btn-soft-primary');
      document.querySelector('.shoppingbtn').classList.add('btn-light');
      document.querySelector('.settingbtn').classList.add('btn-light');
      document.getElementById('topnav').classList.remove('nav-sticky');
    }
  };
  const onSearch = () => {
    setCallSearch(true);
  };

  useEffect(() => {
    document.body.classList = '';
    document.querySelectorAll('#buyButton').forEach((navLink) => {
      navLink.classList.add('btn-light');
      navLink.classList.remove('btn-soft-primary');
      document.getElementById('top-menu').classList.add('nav-light');
    });
    window.addEventListener('scroll', scrollNavigation, true);
  }, []);
  return (
    <React.Fragment>
      {/* breadcrumb */}
      <section
        className="bg-half-170 d-table w-100"
        style={{ background: `url(${bgNature}) center center` }}
      >
        <div className="bg-overlay"></div>
        <Container>
          <Row className="mt-5 justify-content-center">
            <Col lg={12} className="text-center">
              <div className="pages-heading">
                <h4 className="title text-white title-dark mb-0">
                  {' '}
                  All Researchers{' '}
                </h4>
              </div>
              <div className="text-center subcribe-form mt-4 pt-2">
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    onSearch();
                  }}
                >
                  <div>
                    <input
                      type="text"
                      value={filterName}
                      onChange={onChangeFilterName}
                      className="border bg-white rounded-pill"
                      required
                      style={{ opacity: '0.75' }}
                      placeholder="Cari peneliti"
                    />
                    <Button
                      onClick={onSearch}
                      color="primary"
                      className="btn btn-pills btn-primary"
                    >
                      Cari <i className="uil uil-arrow-right"></i>
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>

          <div className="position-breadcrumb">
            <nav aria-label="breadcrumb" className="d-inline-block">
              <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                <li className="breadcrumb-item">
                  <Link to="/">Biodiversitas</Link>
                </li>{' '}
                <li className="breadcrumb-item active" aria-current="page">
                  Researchers
                </li>
              </ul>
            </nav>
          </div>
        </Container>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="section">
        <Container>
          <Row className="justify-content-center">
            <div className="col-12 filters-group-wrap">
              <div className="filters-group">
                <ul
                  className="container-filter list-inline mb-0 filter-options text-center"
                  id="filter"
                >
                  <li
                    onClick={() => setDisplayCategory('All')}
                    className={
                      displayCategory === 'All'
                        ? 'list-inline-item categories-name border text-dark rounded active'
                        : 'list-inline-item categories-name border text-dark rounded'
                    }
                  >
                    All
                  </li>{' '}
                  <li
                    onClick={() => setDisplayCategory('Business')}
                    className={
                      displayCategory === 'Business'
                        ? 'list-inline-item categories-name border text-dark rounded active'
                        : 'list-inline-item categories-name border text-dark rounded'
                    }
                  >
                    Business
                  </li>{' '}
                  <li
                    onClick={() => setDisplayCategory('Marketing')}
                    className={
                      displayCategory === 'Marketing'
                        ? 'list-inline-item categories-name border text-dark rounded active'
                        : 'list-inline-item categories-name border text-dark rounded'
                    }
                  >
                    Marketing
                  </li>{' '}
                  <li
                    onClick={() => setDisplayCategory('Finance')}
                    className={
                      displayCategory === 'Finance'
                        ? 'list-inline-item categories-name border text-dark rounded active'
                        : 'list-inline-item categories-name border text-dark rounded'
                    }
                  >
                    Finance
                  </li>{' '}
                  <li
                    onClick={() => setDisplayCategory('Human Research')}
                    className={
                      displayCategory === 'Human Research'
                        ? 'list-inline-item categories-name border text-dark rounded active'
                        : 'list-inline-item categories-name border text-dark rounded'
                    }
                  >
                    Human Research
                  </li>{' '}
                </ul>
              </div>
            </div>
          </Row>
          <DisplayResearchers
            callSearch={callSearch}
            setCallSearch={setCallSearch}
            displayCategory={displayCategory}
            filters={{ key: filterName }}
          ></DisplayResearchers>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default Homepage;
