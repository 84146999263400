import React from 'react';
import { Redirect } from 'react-router-dom';

//Main Index
import Main from '../pages/Home/indexMain';

//Special
import PageComingSoon from '../pages/Pages/Special/PageComingSoon';

import PageComingSoon2 from '../pages/Pages/Special/PageComingSoon2';

import PageError from '../pages/Pages/Special/PageError';

import PageThankYou from '../pages/Pages/Special/PageThankYou';

import PageMaintenance from '../pages/Pages/Special/PageMaintenance';

//Auth Pages
import PageLogin from '../pages/Pages/AuthPages/PageLogin';

import PageCoverLogin from '../pages/Pages/AuthPages/PageCoverLogin';

import PageLoginThree from '../pages/Pages/AuthPages/PageLoginThree';

import PageLoginFive from '../pages/Pages/AuthPages/PageLoginFive';

import PageSignup from '../pages/Pages/AuthPages/PageSignup';

import PageCoverSignup from '../pages/Pages/AuthPages/PageCoverSignup';

import PageSignupThree from '../pages/Pages/AuthPages/PageSignupThree';

import PageSignupFive from '../pages/Pages/AuthPages/PageSignupFive';

import PageCoverRePassword from '../pages/Pages/AuthPages/PageCoverRePassword';

import PageRecoveryPassword from '../pages/Pages/AuthPages/PageRecoveryPassword';

import PageRePasswordThree from '../pages/Pages/AuthPages/PageRePasswordThree';

import PageResetPassFive from '../pages/Pages/AuthPages/PageResetPassFive';

// Import all components
import SaasOnepage from '../pages/Saas Onepage/index';
import Customer from '../pages/Customer/index';
import Software from '../pages/Software/index';
import Payments from '../pages/Payments/index';
import IndexCarRiding from '../pages/Car Ride/index';
import IndexclassicSaas from '../pages/Classic Saas/index';
import ClassicApp from '../pages/Classic Application/index';

import Agency from '../pages/Agency/index';
import Saas from '../pages/Saas/index';
import Apps from '../pages/Apps/index';
import Studio from '../pages/Studio/index';
import Business from '../pages/Business/index';
import ITSolution from '../pages/ITSolution/index';
import Marketing from '../pages/Marketing/index';
import RealEstate from '../pages/RealEstate/index';
import Hotel from '../pages/Hotel/index';
import Developer from '../pages/Developer/index';
import LandingFour from '../pages/LandingFour/index';
import Integration from '../pages/Integration/index';
import TaskManagement from '../pages/TaskManagement/index';
import Hospital from '../pages/Hospital/index';
import Construction from '../pages/Construction/index';
import EmailInbox from '../pages/Email-Inbox/index';
import LandingOne from '../pages/LandingOne/index';
import LandingThree from '../pages/LandingThree/index';
import LandingTwo from '../pages/LandingTwo/index';
import Travel from '../pages/Travel/index';
import SeoAgency from '../pages/SeoAgency/index';
import ModernBusiness from '../pages/ModernBusiness/index';
import CorporateBusiness from '../pages/CorporateBusiness/index';

import Coworking from '../pages/Coworking/index';
import Event from '../pages/Event/index';
import Course from '../pages/Course/index';
import Personal from '../pages/Personal/index';
import SingleProduct from '../pages/SingleProduct/index';
import Enterprise from '../pages/Enterprise/index';
import Services from '../pages/Services/index';
import Insurance from '../pages/Insurance/index';
import Ebook from '../pages/Ebook/index';
import SocialMarketing from '../pages/SocialMarketing/index';

import DigitalAgency from '../pages/DigitalAgency/index';
import OnlineLearning from '../pages/OnlineLearning/index';
import Finance from '../pages/Finance/index';
import Logistics from '../pages/Logistics/index';
import VideoCall from '../pages/VideoCall/index';
import iTSolutionTwo from '../pages/ITSolutionTwo/index';
import Freelancer from '../pages/Freelancer/index';
import Blockchain from '../pages/Blockchain/index';
import CryptoTwo from '../pages/CryptoTwo/index';

//Docs
import ChangeLog from '../pages/Docs/ChangeLog';
import Documentation from '../pages/Docs/Documentation';
import Widget from '../pages/Docs/widget';

//Components
import Buttons from '../pages/Components/Buttons';
import Badges from '../pages/Components/Badges';
import Alerts from '../pages/Components/Alerts';
import Dropdowns from '../pages/Components/Dropdowns';
import Typography from '../pages/Components/Typography';
import Background from '../pages/Components/Background';
import TextColors from '../pages/Components/TextColors';
import Accordions from '../pages/Components/Accordions';
import BoxShadows from '../pages/Components/BoxShadows';
import Borders from '../pages/Components/Borders';
import Carousel from '../pages/Components/Carousel';
import Lightbox from '../pages/Components/Lightbox';
import Tooltips from '../pages/Components/Tooltips';
import Cards from '../pages/Components/Cards';
import Forms from '../pages/Components/Forms';
import Breadcrumbs from '../pages/Components/Breadcrumbs';
import Paginations from '../pages/Components/Paginations';
import Avatar from '../pages/Components/Avatar';
import NavTabs from '../pages/Components/NavTabs';
import Modals from '../pages/Components/Modals';
import Tables from '../pages/Components/Tables';
import Icons from '../pages/Components/Icons';
import Progressbar from '../pages/Components/Progressbar';

import PageAboutUs from '../pages/Pages/PageAboutUs';
import PageAboutusTwo from '../pages/Pages/PageAboutusTwo';
import PageHistory from '../pages/Pages/PageHistory';
import PageMembers from '../pages/Pages/Account/PageMembers';

import PageWorks from '../pages/Pages/Account/PageWorks';
import PageMessages from '../pages/Pages/Account/PageMessages';

import PagePricing from '../pages/Pages/PagePricing';
import PageServices from '../pages/Pages/PageServices';
import PageTeam from '../pages/Pages/PageTeam';

//Account
import PageProfile from '../pages/Pages/Account/PageProfile';
import PageProfileEdit from '../pages/Pages/Account/PageProfileEdit';
import PagePayments from '../pages/Pages/Account/PagePayments';
import PageInvoice from '../pages/Pages/Account/PageInvoice';
import PageChat from '../pages/Pages/Account/PageChat';

//Blog
import PageBlog from '../pages/Pages/Blog/PageBlog';
import PageBlogDetail from '../pages/Pages/Blog/PageBlogDetail';
import PageBlogDetailTwo from '../pages/Pages/Blog/PageBlogDetailTwo';
import PageBlogSidebar from '../pages/Pages/Blog/PageBlogSidebar';
import PageBlogList from '../pages/Pages/Blog/PageBlogList';
import PageBlogListSidebar from '../pages/Pages/Blog/PageBlogListSidebar';

//Case Study
import AllCases from '../pages/Pages/CaseStudy/AllCases';
import CaseDetail from '../pages/Pages/CaseStudy/CaseDetail';

//course-detail
import CourseDetail from '../pages/Pages/CourseDetail/index';

//Utility
import PagePrivacy from '../pages/Pages/Utility/PagePrivacy';
import PageTerms from '../pages/Pages/Utility/PageTerms';

//Contact
import PageContactDetail from '../pages/Pages/Contact/PageContactDetail';
import PageContactOne from '../pages/Pages/Contact/PageContactOne';
import PageContactThree from '../pages/Pages/Contact/PageContactThree';
import PageContactTwo from '../pages/Pages/Contact/PageContactTwo';

// forums
import Overview from '../pages/Pages/Forums/overview';
import ForumTopic from '../pages/Pages/Forums/ForumTopic';
import ForumsComments from '../pages/Pages/Forums/ForumsComments';

//Email
import EmailAlert from '../pages/Pages/EmailTemplate/EmailAlert';
import EmailPasswordReset from '../pages/Pages/EmailTemplate/EmailPasswordReset';
import EmailConfirmation from '../pages/Pages/EmailTemplate/EmailConfirmation';
import EmailInvoice from '../pages/Pages/EmailTemplate/EmailInvoice';

//Help Center
import HelpCenterOverview from '../pages/Pages/HelpCenter/HelpCenterOverview';
import HelpCenterFaqs from '../pages/Pages/HelpCenter/HelpCenterFaqs';
import HelpCenterGuides from '../pages/Pages/HelpCenter/HelpCenterGuides';
import HelpCenterSupportRequest from '../pages/Pages/HelpCenter/HelpCenterSupportRequest';

//Demo Shop
import Shop from '../pages/Pages/DemoShop/Shop';
import ShopAboutus from '../pages/Pages/DemoShop/ShopAboutus';
import ShopFullwidthGrids from '../pages/Pages/DemoShop/ShopFullwidthGrids';
import ShopGrid from '../pages/Pages/DemoShop/ShopGrid';
import ShopFullwidthLists from '../pages/Pages/DemoShop/ShopFullwidthLists';
import ShopLists from '../pages/Pages/DemoShop/ShopList';
import ShopProductDetail from '../pages/Pages/DemoShop/ShopProductDetail';
import ShopCart from '../pages/Pages/DemoShop/ShopCart';
import ShopCheckouts from '../pages/Pages/DemoShop/ShopCheckouts';
import ShopMyAccount from '../pages/Pages/DemoShop/ShopMyaccount';
import ShopBlog from '../pages/Pages/DemoShop/ShopBlog';
import ShopBlogDetails from '../pages/Pages/DemoShop/ShopBlogDetails';

//Corporate Buisness
import CorporateAbout from '../pages/Pages/CorporateAbout';
import CorporateServices from '../pages/Pages/CorporateServices';
import CorporateTeam from '../pages/Pages/CorporateTeam';
import CorporatePricing from '../pages/Pages/CorporatePricing';
import CorporateBlog from '../pages/Pages/CorporateBlog';
import CorporateBlogDetail from '../pages/Pages/CorporateBlogDetail';

//Demo Portfolio
import Portfolio from '../pages/Pages/DemoPortfolio/Portfolio';
import PageWorkModernTwo from '../pages/Pages/DemoPortfolio/PageWorkModernTwo';
import PageWorkModernThree from '../pages/Pages/DemoPortfolio/PageWorkModernThree';
import PageWorkModernFour from '../pages/Pages/DemoPortfolio/PageWorkModernFour';
import PageWorkModernFive from '../pages/Pages/DemoPortfolio/PageWorkModernFive';
import PageWorkModernSix from '../pages/Pages/DemoPortfolio/PageWorkModernSix';
import PageWorkClassicTwo from '../pages/Pages/DemoPortfolio/PageWorkClassicTwo';
import PageWorkClassicThree from '../pages/Pages/DemoPortfolio/PageWorkClassicThree';
import PageWorkClassicFour from '../pages/Pages/DemoPortfolio/PageWorkClassicFour';
import PageWorkClassicFive from '../pages/Pages/DemoPortfolio/PageWorkClassicFive';
import PageWorkClassicSix from '../pages/Pages/DemoPortfolio/PageWorkClassicSix';
import PageWorkGridTwo from '../pages/Pages/DemoPortfolio/PageWorkGridTwo';
import PageWorkGridThree from '../pages/Pages/DemoPortfolio/PageWorkGridThree';
import PageWorkGridFour from '../pages/Pages/DemoPortfolio/PageWorkGridFour';
import PageWorkGridFive from '../pages/Pages/DemoPortfolio/PageWorkGridFive';
import PageWorkGridSix from '../pages/Pages/DemoPortfolio/PageWorkGridSix';
import PageWorkMasonryTwo from '../pages/Pages/DemoPortfolio/PageWorkMasonryTwo';
import PageWorkDetailOne from '../pages/Pages/DemoPortfolio/PageWorkDetailOne';
import PageWorkDetailTwo from '../pages/Pages/DemoPortfolio/PageWorkDetailTwo';
import PageWorkDetailThree from '../pages/Pages/DemoPortfolio/PageWorkDetailThree';
import PageWorkDetailFour from '../pages/Pages/DemoPortfolio/PageWorkDetailFour';
import PortfolioAbout from '../pages/Pages/DemoPortfolio/Portfolio/PortfolioAbout';
import PortfolioService from '../pages/Pages/DemoPortfolio/Portfolio/PortfolioService';

import PageMasonryTwo from '../pages/Pages/DemoPortfolio/PageMasonryTwo';
import PageMasonryThree from '../pages/Pages/DemoPortfolio/PageMasonryThree';
import PageMasonryFour from '../pages/Pages/DemoPortfolio/PageMasonryFour';
import PageMasonryFive from '../pages/Pages/DemoPortfolio/PageMasonryFive';
import PageMasonrySix from '../pages/Pages/DemoPortfolio/PageMasonrySix';

//Career
import Job from '../pages/Pages/DemoJob/Job';
import PageCompanyList from '../pages/Pages/DemoJob/PageCompanyList';
import JobListone from '../pages/Pages/DemoJob/JobListOne/JobListOne';
import JobListTwo from '../pages/Pages/DemoJob/JobListTwo';
import JobListThree from '../pages/Pages/DemoJob/JobListThree';
import JobListFour from '../pages/Pages/DemoJob/JobListFour';
import JobListFive from '../pages/Pages/DemoJob/JobListFive';
import PageJobDetail from '../pages/Pages/DemoJob/PageJobDetail';
import PageJobDetailTwo from '../pages/Pages/DemoJob/PageJobDetailTwo';
import PageJobApply from '../pages/Pages/DemoJob/PageJobApply';
import PageCandidateList from '../pages/Pages/DemoJob/PageCandidateList';
import PageJobCandidate from '../pages/Pages/DemoJob/PageJobCandidate';
import PageJobCompany from '../pages/Pages/DemoJob/PageJobCompany';
import JobAbout from '../pages/Pages/DemoJob/JobAbout';
import JobPrice from '../pages/Pages/DemoJob/JobPrice';
import JobFaq from '../pages/Pages/DemoJob/JobFaq';
import AuthLogin from '../pages/Pages/DemoJob/AuthLogin';
import AuthSignup from '../pages/Pages/DemoJob/AuthSignup';
import AuthReset from '../pages/Pages/DemoJob/AuthReset';

//Cryptocurrency
import Cryptocurrency from '../pages/DemoCryptocurrency/Cryptocurrency/index';
import CryptoAbout from '../pages/DemoCryptocurrency/AboutUs';
import CryptoMarket from '../pages/DemoCryptocurrency/CryptoMarket';
import CryptoToken from '../pages/DemoCryptocurrency/CryptoToken';
import CryptoServices from '../pages/DemoCryptocurrency/CryptoServices';
import CryptoFaqs from '../pages/DemoCryptocurrency/CryptoFaqs';
import CryptoWhitepaper from '../pages/DemoCryptocurrency/CryptoWhitepaper';
import CryptoBlog from '../pages/DemoCryptocurrency/CryptoBlog';
import CryptoBlogDetail from '../pages/DemoCryptocurrency/CryptoBlogDetail';

import PageJobDetailThree from '../pages/Pages/DemoJob/PageJobDetailThree';

import PageJobsSidebar from '../pages/Pages/Careers/PageJobsSidebar';

import PageJob from '../pages/Pages/Careers/PageJob';

//Demo Blog
import Blog from '../pages/Pages/DemoBlog/Blog/index';
import BlogAbout from '../pages/Pages/DemoBlog/BlogAbout';
import BlogStandardPost from '../pages/Pages/DemoBlog/BlogStandardPost';
import BlogSliderPost from '../pages/Pages/DemoBlog/BlogSliderPost';
import BlogGalleryPost from '../pages/Pages/DemoBlog/BlogGalleryPost';
import BlogYoutubePost from '../pages/Pages/DemoBlog/BlogYoutubePost';
import BlogVimeoPost from '../pages/Pages/DemoBlog/BlogVimeoPost';
import BlogAudioPost from '../pages/Pages/DemoBlog/BlogAudioPost';
import BlogBlockquotePost from '../pages/Pages/DemoBlog/BlogBlockquotePost';
import BlogLeftSliderPost from '../pages/Pages/DemoBlog/BlogLeftSidebarPost';

//nft marketplace
import Nftmarketplace from '../pages/Pages/NFTMarketPlace/index';
import NftExplore from '../pages/Pages/NFTMarketPlace/NftExplore';
import NftCreators from '../pages/Pages/NFTMarketPlace/NftCreators';
import NftItemDetail from '../pages/Pages/NFTMarketPlace/NftItemDetail';

//CloudHosting
import CloudHosting from '../pages/DemoCloudHosting/index';
import HostingDomain from '../pages/DemoCloudHosting/HostingDomain';
import HostingShared from '../pages/DemoCloudHosting/HostingShared';
import HostingVps from '../pages/DemoCloudHosting/HostingVps';
import HostingDedicated from '../pages/DemoCloudHosting/HostingDedicated';
import HostingCloud from '../pages/DemoCloudHosting/HostingCloud';
import HostingReseller from '../pages/DemoCloudHosting/HostingReseller';
import HostingAboutus from '../pages/DemoCloudHosting/HostingAboutus';
import HostingServices from '../pages/DemoCloudHosting/HostingServices';
import HostingFaqs from '../pages/DemoCloudHosting/HostingFaqs';
import HostingBlog from '../pages/DemoCloudHosting/HostingBlog';
import HostingBlogDetail from '../pages/DemoCloudHosting/HostingBlogDetail';

//Forums
import Forums from '../pages/Forums/index';

//Footer layouts
import PageFooterLayouts from '../pages/Pages/PageFooterLayouts/PageFooterLayouts';

import BiodiversitasHomePage from '../pages/Biodiversitas/HomePage';

import ResearchersPage from '../pages/Biodiversitas/ReseachersPage';

const routes = [
  //Index Main
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/index" />,
  },
  { path: '/news', component: PageBlog },
  { path: '/collections', component: PageBlogList },
  { path: '/researchers', component: ResearchersPage },
  { path: '/index', component: BiodiversitasHomePage },
  { path: '/index-task-management', component: TaskManagement },
  {
    path: '*',
    component: PageError,
  },
];

export default routes;
