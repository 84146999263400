import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card } from 'reactstrap';

// Import images
import img3 from '../../assets/images/biodiversitas/ocean.jpg';

const Section = () => {
  return (
    <React.Fragment>
      {/* Hero Start */}
      <section
        className="bg-half-170 d-table w-100"
        style={{ background: `url(${img3}) center center` }}
        id="home"
      >
        <Container>
          <Row className="position-relative align-items-center pt-4">
            <Col lg={7} className="offset-lg-5">
              <Card className="title-heading studio-home rounded shadow mt-5">
                <h1 className="heading mb-3">
                  <span className="text-primary">Biodiversitas</span> Indonesia
                </h1>
                <p className="para-desc text-muted">
                  Aplikasi Biodiversitas Indonesia adalah sebuah platform
                  digital yang dirancang untuk mempelajari, memahami, dan
                  melindungi keanekaragaman hayati yang kaya di Indonesia
                </p>
                <div className="mt-4">
                  <Link to="/researchers" className="btn btn-primary mt-2 me-2">
                    <i className="fa fa-users"></i> Reseachers
                  </Link>{' '}
                  <Link
                    to="/collections"
                    className="btn btn-outline-primary mt-2"
                  >
                    <i className="uil uil-book-alt"></i> Our Collections
                  </Link>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Section;
