import React from 'react';
import { useState } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { BeatLoader } from 'react-spinners';
import {
  Card,
  CardBody,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from 'reactstrap';
import { ResearcherApi } from '../../api/ResearcherApi';
import { useEffect } from 'react';
import noImageAvailable from '../../assets/images/no_image_available.jpg';
const CustomPagination = (props) => {
  let maxPaging = 10;
  const { currentPage, onPaging, lastPage, ...otherProps } = props;
  const [pagingView, setPagingView] = useState();
  useEffect(() => {
    if (lastPage == 1) {
      setPagingView(<></>);
    } else if (lastPage > 0) {
      setPagingView(
        <Pagination
          className="mb-0"
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '10px',
          }}
        >
          {Array(lastPage)
            .fill('x')
            .map((v, i) => {
              let ipage = i + 1;
              if (ipage == 1)
                return (
                  <PaginationItem key={ipage} active={currentPage == 1}>
                    <PaginationLink
                      onClick={() => {
                        onPaging(ipage);
                      }}
                    >
                      First
                    </PaginationLink>
                  </PaginationItem>
                );
              if (ipage == lastPage)
                return (
                  <PaginationItem key={ipage} active={currentPage == lastPage}>
                    <PaginationLink
                      onClick={() => {
                        onPaging(ipage);
                      }}
                      next
                    >
                      Last
                    </PaginationLink>
                  </PaginationItem>
                );
              let sisa = (maxPaging - 2) / 2;
              let kiri = currentPage - sisa;
              let kanan = currentPage + sisa;
              if (kiri < 0) {
                let cek = kiri * -1;
                kanan = kanan + cek;
              }
              if (kanan < 0) {
                let cek = kanan * -1;
                kiri = kiri - cek;
              }
              console.log('ipage' + ipage);
              console.log('sisa ' + sisa);
              console.log('kiri ' + kiri);
              console.log('kanan ' + kanan);
              if (ipage <= kanan && ipage >= kiri)
                return (
                  <PaginationItem key={i} active={currentPage == ipage}>
                    <PaginationLink
                      onClick={() => {
                        onPaging(ipage);
                      }}
                    >
                      {ipage}
                    </PaginationLink>
                  </PaginationItem>
                );
            })}
        </Pagination>,
      );
    }
  }, [currentPage]);

  return <>{pagingView}</>;
};
const DisplayResearchers = (props) => {
  const { filters, displayCategory, callSearch, setCallSearch, ...otherProps } =
    props;
  const [isLoading, setIsLoading] = useState(false);
  const [researchers, setResearchers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);

  const onPaging = (ipage) => {
    onSearch(ipage);
  };

  useEffect(() => {
    if (callSearch) {
      onSearch(1);
    }
  }, [callSearch]);
  const onSearch = (page) => {
    let _filters = { ...filters, ...{ page: page } };
    setIsLoading(true);
    ResearcherApi.search(_filters)
      .then((res) => {
        setResearchers(res?.data?.data);
        setCurrentPage(res?.data?.current_page);
        setLastPage(res?.data?.last_page);
      })
      .finally(() => {
        setIsLoading(false);
        setCallSearch(false);
      });
  };
  useEffect(() => {
    onSearch(1);
  }, []);
  return (
    <>
      {isLoading && (
        <center>
          <BeatLoader></BeatLoader>
        </center>
      )}
      {!isLoading && (
        <Row className="projects-wrapper">
          {researchers
            .filter(
              ({ category }) =>
                displayCategory === category || displayCategory === 'All',
            )
            .map((researcher, key) => (
              <Col
                key={key}
                lg={4}
                md={6}
                xs={12}
                className="mt-4 pt-2 business box-researcher"
              >
                <FadeIn delay={100}>
                  <Card className="blog border-0 work-container work-primary work-classic shadow rounded-md overflow-hidden">
                    <img
                      style={{ display: 'none' }}
                      src={
                        researcher?.username_intra
                          ? `https://intra.brin.go.id/api/user/foto_link?usernameintra=${researcher.username_intra}`
                          : noImageAvailable
                      }
                      className="img-fluid work-image image-researcher"
                      alt="Landrick"
                    />
                    <div
                      className="image-researcher"
                      style={{
                        width: '100%',
                        height: '300px',
                        backgroundImage: `url(${
                          researcher.username_intra
                            ? 'https://intra.brin.go.id/api/user/foto_link?usernameintra=' +
                              researcher.username_intra
                            : noImageAvailable
                        })`,
                        backgroundColor: 'aqua',
                      }}
                    ></div>
                    <CardBody>
                      <div className="content">
                        {researcher.isBusiness && (
                          <Link to="#" className="badge badge-link bg-primary">
                            Business
                          </Link>
                        )}
                        {researcher.isMarketing && (
                          <Link to="#" className="badge badge-link bg-warning">
                            Marketing
                          </Link>
                        )}
                        {researcher.isFinance && (
                          <Link to="#" className="badge badge-link bg-success">
                            Finance
                          </Link>
                        )}
                        {researcher.isHR && (
                          <Link to="#" className="badge badge-link bg-info">
                            HR
                          </Link>
                        )}
                        <h5 className="mt-3">
                          <Link
                            to="page-case-detail"
                            className="text-dark title"
                          >
                            {researcher.title}
                          </Link>
                        </h5>
                        <p className="text-muted">{researcher.name}</p>
                        <Link to="#" className="link h6">
                          Read More{' '}
                          <i className="uil uil-angle-right-b align-middle"></i>
                        </Link>
                      </div>
                    </CardBody>
                  </Card>
                </FadeIn>
              </Col>
            ))}

          <CustomPagination
            onPaging={onPaging}
            currentPage={currentPage}
            lastPage={lastPage}
          ></CustomPagination>
        </Row>
      )}
    </>
  );
};
export default DisplayResearchers;
