import ApiCall from './Api';
const listResearcher = async (page) => {
  return await ApiCall.get(`/authorization?page=` + page).then((res) => {
    return res;
  });
};
const searchResearcher = async (params) => {
  params = params || [];
  let p = Object.keys(params)
    .map((v, i) => {
      if (Array.isArray(params[v])) {
        return params[v]
          .map((c, ix) => {
            return v + '=' + encodeURI(c);
          })
          .join('&');
      }
      return v + '=' + encodeURI(params[v]);
    })
    .join('&');
  return await ApiCall.get(`/searching-peneliti?` + p).then((res) => {
    return res;
  });
};
const editResearcher = async (id) => {
  return await ApiCall.get(`/authorization/${id}/edit`).then((res) => {
    return res;
  });
};
const updateResearcher = async (id, formData) => {
  formData.append('_method', 'PATCH');
  return await ApiCall.post(`/authorization/${id}`, formData, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  }).then((res) => {
    return res;
  });
};
const deleteResearcher = async (id, formData) => {
  return await ApiCall.delete(`/authorization/${id}`, formData).then((res) => {
    return res;
  });
};
const addResearcher = async (formData) => {
  await ApiCall.post(`/authorization`, formData).then((res) => {
    return res;
  });
};
export const ResearcherApi = {
  list: listResearcher,
  edit: editResearcher,
  add: addResearcher,
  update: updateResearcher,
  delete: deleteResearcher,
  search: searchResearcher,
};
